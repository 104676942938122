import { Auth } from 'aws-amplify';

let urlRoute = process.env.REACT_APP_MOCAFI_API_ROUTE;

const getDomain = async (municipalityName: string, cognitoToken: string) => {
  const token = await Auth.currentSession();
  const municipality = token.getIdToken().payload['custom:Municipality'];

  const myInit = {
    // OPTIONAL
    headers: {
      'Content-type': 'application/json',
      'mocafi-username': token.getIdToken().payload['cognito:username'],
      token: `Bearer ${cognitoToken}`,
      city: municipality,
      municipality,
    },
    method: 'GET',
    // response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  const response = await fetch(
    `${urlRoute}irc/irc-products/municipalities/${municipalityName}`,
    myInit
  )
    .then((response) => response.json())
    .catch(function (error) {
      return error;
    });
  return response;
};

export { getDomain };
